<template>
    <div class='divider' :style='getStyle'>
    </div>
</template>
<script>
export default {
     name: 'Divider',
         data () {
             return {
         }
     },

     props: ['element', 'settings'],

     computed: {
        getStyle () {
            return {
                'color': this.settings.buttonColor,
                'border-color': this.settings.borderColor,
            }
        }
     },

     methods: {
     }
}
</script>
<style type='text/css' scoped lang='scss'>
.divider {
    display: flex;
    flex-direction: column;
    margin: 35px 0px;
    border-bottom: 1px solid black; 
    
}



</style>
<template>
<div class='submit-wrapper'>
    <div class='error-message-wrapper' :style='$parent.getErrorStyle' v-if='$parent.showErrorMessage'>
         {{getErrorMessage}}
    </div>
    <div class='input-wrapper'>
        <button 
            class='button'  
            type='submit' 
            @click="submitForm" 
            :style='getStyle' 
            :id='"element_" + element.id'
            @mouseenter='hover = true' 
            @mouseleave='hover = false'
        >
            {{element.label[$parent.language]}}
        </button>
    </div>
</div>
</template>
<script>
export default {
     name: 'Submit',
         data () {
             return {
                val: 1,
                options: [
                ],
                hover: false,
                translations: {
                    '1': 'Er is iets mis gegaan met versturen. Probeer het opnieuw.',
                    '2': 'Something went wrong sending the form. Please try again.'
                }
         }
     },
    component: {
    },
     props: ['element', 'settings'],

     computed: {
        getStyle () {
            if(this.hover) { 
                return {
                    'background-color': this.settings.buttonBackgroundColor,
                    'border-radius': this.settings.buttonType === 'square' ? '0px' : this.settings.buttonType === 'rounded' ? '3px' : '50px',
                    'border-color': this.settings.buttonBorderColor,
                    'color': this.settings.buttonColor,
                    'font-family': this.settings.buttonFontFamily.family_name,
                    'font-weight': this.settings.buttonFontFamily.font_weight,
                    'font-style': this.settings.buttonFontFamily.font_style
                }
            } else {
                 return {
                    'background-color': this.settings.buttonBackgroundColor,
                    'border-radius': this.settings.buttonType === 'square' ? '0px' : this.settings.buttonType === 'rounded' ? '3px' : '50px',
                    'border-color': this.settings.buttonBorderColor,
                    'font-family': this.settings.buttonFontFamily.family_name,
                    'font-weight': this.settings.buttonFontFamily.font_weight,
                    'font-style': this.settings.buttonFontFamily.font_style,
                    'color': this.settings.buttonColor
                }
            }
        },
        getErrorMessage () {
            return this.$parent.getFieldError(this.element) || this.translations[this.$parent.language == '1' ? '1' : '2'];
        }
     },

     methods: {
         submitForm (e) {
            e.preventDefault();
            this.$emit('submit');
         }
     }
}
</script>
<style type='text/css' scoped lang='scss'>
.input-wrapper {
    display:flex;
    align-items: center;
    font-size: 16px;
}

.submit-wrapper {
    margin-top: 25px;
}
.button {
    cursor: pointer;
    padding: 11px 25px;
    font-weight: bold;
    font-size: 18px;
    outline: none;
    border: none;
}

.button:hover {
    transform: scale(1.02);
}
.error-message-wrapper {
    height: 40px;
    display: inline-flex;
    align-items: center;
    background-color: #D0021B;
    margin-bottom: 20px;
    color: white;
    padding: 0px 20px;
}
.success-message-wrapper {
    height: 40px;
    display: inline-flex;
    align-items: center;
    background-color: #02d013;
    margin-bottom: 20px;
    color: white;
    padding: 0px 20px;
}
</style>
<template>
    <fieldset class='text-wrapper' >
        <legend class='label' :style='getStyle'>{{element.label[$parent.language]}}</legend>
    </fieldset>
</template>
<script>
export default {
     name: 'TextElement',
         data () {
             return {
         }
     },

     props: ['element', 'settings'],
    created() {
    },
     computed: {
        getStyle () {
            return {
                'color': this.settings.titleTextColor,
                'font-family': this.settings.titleFontFamily.family_name,
                'font-weight': this.settings.titleFontFamily.font_weight,
                'font-style': this.settings.titleFontFamily.font_style,
            }
        }
     },
     methods: {
     }
}
</script>
<style type='text/css' scoped lang='scss'>
.text-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    .label {
        margin-bottom: 10px;
    }
}
legend {
    font-size: 30px;
}


</style>
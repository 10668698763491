import Vue from 'vue'
import Router from 'vue-router';
import Confirmation from '../pages/Confirmation.vue';


Vue.use(Router);
var base = '/'
let lang = location.pathname.split('/')[1];
export default new Router({
  base: base,
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/nl_NL',
    },
    {
      path: '/confirm',
      component: Confirmation,
      name: 'Confirmation'
    },
  ]
})

<template>
    <div class="cf-turnstile-renderer"></div>
</template>
<script>


export default {
    name: 'Captcha',
    props: ['element', 'settings'],

    mounted() {
        const siteKey = process.env.VUE_APP_CLOUDFLARE_TURNSTILE_SITE_KEY;
        
        let captcha = document.createElement('script')
        captcha.setAttribute('src', 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback')
        captcha.setAttribute('async', true)
        captcha.setAttribute('defer', true)
        document.head.appendChild(captcha)

        var self = this;
        window.onloadTurnstileCallback = function () {
            turnstile.render('.cf-turnstile-renderer', {
                sitekey: siteKey,
                callback: function (token) {
                    self.$emit("updateCaptcha", token)
                },
            });
        };
    },
}
</script>
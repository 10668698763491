import Vue from 'vue';
import App from './App.vue';
import router from './router/router';

Vue.config.productionTip = false;

import TextInput from "./components/textInput";
import TextArea from "./components/textArea";
import Select from "./components/select";
import RadioButton from "./components/radio";
import Submit from "./components/submit";
import Checkbox from "./components/checkbox";
import MultiCheckbox from "./components/multiCheckbox";
import TextElement from "./components/textElement";
import Title from "./components/title";
import Divider from "./components/divider";
import Captcha from "./components/captcha"

Vue.component('MaglrTextInput', TextInput);
Vue.component('MaglrTextArea', TextArea);
Vue.component('MaglrSelect', Select);
Vue.component('MaglrRadioButton', RadioButton);
Vue.component('MaglrSubmit', Submit);
Vue.component('MaglrCheckbox', Checkbox);
Vue.component('MaglrMultiCheckbox', MultiCheckbox);
Vue.component('MaglrDivider', Divider);
Vue.component('MaglrTitle', Title);
Vue.component('MaglrTextElement', TextElement);
Vue.component('MaglrCaptcha', Captcha);

window.startVueForm = function() {
  window.vueInstance = new Vue({
    render: h => h(App),
    router
  }).$mount('#app');
}

window.removeEventListener('message', handleMessage, true);
window.addEventListener('message', handleMessage, true);

function handleMessage (e) {
  if (e.data.message === "DESTROY_FORM_RENDERER") {
        window.vueInstance.$destroy();
        let scripts = document.getElementsByTagName('script');
        scripts.forEach((scrpt, i) => {
          if(scripts[i].outerHTML.indexOf('hsforms') > -1) {
            scripts[i].setAttribute('src', '');
            scripts[i].parentNode.removeChild(scripts[i]);
          }
        });
    }
}

window.startVueForm();





<template>
     <div :class='getLabelPosition' class='container'>
         <label class='label' :style='getLabelStyle' :id='element.label[$parent.language]'>{{element.label[$parent.language]}}</label>
         <div class='checkboxes-wrapper'>
            <div class='checkbox-wrapper' v-for='(option) in element.options' :key='element.id+"_"+option.id'>
                <div class='cb-label' >
                    <input
                        class='input'
                        :label='option.label[$parent.language]'
                        :id='"element_" + element.id + "_" + option.id'
                        type='checkbox'
                        :aria-required="element.mandatory"
                        :aria-invalid="element.error"
                        v-model='checkedValues'
                        :value='option.value'
                    />
                    <label :for='"element_" + element.id + "_" + option.id'  :style='$parent.getTextStyle'>{{option.label[$parent.language]}}</label>
                    <span @click='optionClicked(option)' class="checkmark" :class='{ "checked": optionFoundInCheckedValues(option) }' :style='getStyle'></span>
                </div>
            </div>
            <label class='error-label' :style='$parent.getErrorStyle' v-if='element.error'>{{$parent.getFieldError(element)}}</label>
         </div>
     </div>
</template>
<script>
export default {
    name: 'MultiCheckBox',
    data () {
        return {
          checkedValues: null,
        }
    },
    created () {
      console.log(this.$parent.getTextStyle);
      this.checkedValues = [];
    },
    computed: {
        getLabelStyle () {
            return {
                'font-family': this.settings.labelFontFamily.family_name,
                'font-weight': this.settings.labelFontFamily.font_weight,
                'font-style': this.settings.labelFontFamily.font_style,
                'color': this.settings.labelColor
            }
        },
        getStyle () {
          if(this.element.error) {
            return {
              'background-color': this.settings.inputBackgroundColor,
              'border-width': this.settings.borderWidth,
              'border-color': this.element.error ? this.settings.errorBackgroundColor : this.settings.borderColor,
            }
          }
          return {};
        },
        getLabelPosition () {
          return {'container-row': this.settings.labelPosition === 'left', 'container-col': this.settings.labelPosition === 'top', "error": this.element.error  };
        },
    },
    methods: {
      optionClicked (option) {
        let hasValue = false;
        this.checkedValues.forEach((val, i) => {
          if (val === option.value) {
            this.checkedValues.splice(i, 1);
            hasValue = true;
          }
        });
        if (!hasValue) {
          this.checkedValues.push(option.value);
        }
      },

      optionFoundInCheckedValues (option) {
        return this.checkedValues.find(value => {
          return value === option.value;
        });
      }
    },
    props: ['element', 'settings'],
    watch: {
       element: {
        handler (newVal){
          if (this.$parent.hasBeenSubmitted) {
            this.$emit('errorCheck');
          }
        },
        deep: true
      },
      checkedValues (newVal) {
        this.$emit('updateElement', this.element.id, newVal);
      }
    },
}
</script>
<style type='text/css' scoped lang='scss'>
/* Customize the label (the container) */
.container-row {
    align-items: start;
    .checkbox-wrapper {
        margin-bottom: 20px;
        flex-direction: column;
    }
    .error-label {
        bottom: -30px;
    }
}
.cb-label {
  line-height: 22px;
  label {
    cursor: pointer;
  }
}
.label {
  font-size: 16px;
  margin-bottom: 10px;
}

.checkbox-wrapper {
  display: block;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  label {
    font-size: 16px;
    padding-left: 35px;
    display: block;

  }
  &:focus-within {
    &.checkmark {
      outline: #000 solid 1px  !important;
    }
  }
}

/* Hide the browser's default radio button */
input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
input:focus + .checkmark {
    outline: #000 solid 1px !important;
}
/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  background-color: #fff;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: #B8B8B8;
  border: 1px solid #B8B8B8;
    &.checked{
      &:after {
        display: block;
      }
    }
    &:after {
      width: 6px;
      height: 6px;
      padding: 3px;
      background-color: #2196F3 !important;
    }
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.container input:focus ~ .checkmark {
  border-color: #000 !important;
}

</style>

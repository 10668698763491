<template>
<div id='form-renderer' :style='getStyle' :class='{ "hubspot-bg": formType === 1 || formType === 2 }'>
  <router-view></router-view>
  <form id='renderer-wrapper' v-if='formType === 0' :class='{ "form-gated": isFormGated || isBuildMode }'>
    <div  v-if='loaded && !waitingForApi'>
      <div v-if='showSuccessMessage' class='success-message-wrapper' :style='getTextStyle'>
          {{ getSubmitSuccessMessage }}
      </div>
      <div v-else>
        <component v-for='el in form_data'  :is='"Maglr"+el.type' :element='el' :key='el.id' :settings='settings' @updateElement='updateElement' @submit='submitForm' @errorCheck='elementErrorCheck(el)' @updateCaptcha="updateCaptcha"></component>
        <span class='builder-error-message' :style='getErrorStyle' v-if='showIsInBuildModeError'>{{ getBuildModeError }}</span>
      </div>
    </div>
    <div class='form-missing-wrapper' v-if='showFormMissing'>
      <h5 class='form-missing-text'>
        Maglr isn't able to find the form. Are you sure it still exists?
      </h5>
    </div>
  </form>
  <div v-if="waitingForApi" class="loading-overlay">
    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
        <path :style="getLoaderStyle" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite" />
      </path>
    </svg>
  </div>
</div>
</template>
<script>
import axios from 'axios';

export default {
  data () {
    return {
      submitting:  false,
      hasBeenSubmitted: false,
      form_data: [],
      settings: {},
      language: 'en_EN',
      loaded: false,
      showErrorMessage: false,
      showSuccessMessage: false,
      uuid: null,
      submitAction: 0,
      submitValue: '',
      formId: null,
      formHash: '',
      formName: '',
      hostname: '',
      captcha: '',
      formType: 0,
      showFormMissing: false,
      hsScript: null,
      fontsLoaded: false,
      shouldPing: true,
      pingIndex: 0,
      mailChimpHtml: '',
      formSubmitted: false,
      waitingForApi: false,
      redirectURL: null,
      redirectInIframe: false,
      showIsInBuildModeError: false,
      successTranslations: {
        '1': 'Bedankt voor je reactie. De gegevens zijn verzonden.',
        '2': 'Thanks for filling in the form. The data has been sent.'
      },
      buildErrorMessage: {
        '1': 'Het is niet mogelijk om een formulier vanuit de editor op te sturen.',
        '2': "It's not possible to submit a form from the editor."
      },
      fieldError: {
        '1': 'Dit veld is verplicht.',
        '2': 'This field is mandatory.'
      }
    }
  },
  created(){
    window.removeEventListener('resize', this.resizeEvent, true);
    window.removeEventListener('message', this.handleMessage, true);
    window.addEventListener('message', this.handleMessage, true);
    window.addEventListener('resize', this.resizeEvent);
    window.jQuery = window.jQuery || (() => ({
      // these are all methods required by HubSpot
      change: () => {},
      trigger: () => {},
    }));
  },

  async mounted() {
    //Reset everything
    this.form = null;
    this.form_data = null;
    this.form_settings = null;
    this.uuid = this.getUUID();

    //Send messages to parent / top
    window.parent.postMessage({message: 'FORM_RENDERER_INIT', uuid: this.uuid}, '*');
    window.top.postMessage({message: 'GET_TOP_URL'}, '*');

    let hash = this.getQueryParams('hash', window.location);
    let clientid = this.getQueryParams('clientid', window.location);
    if(hash && clientid){
      const dataurl = process.env.VUE_APP_MAGLR_DATA_URL;
      const timestamp = this.getPropFromUrl(window.location.href, 't');
      let url = dataurl + `/${clientid}/form/${hash}.json?t=${timestamp}`
      let e = await axios.get(url).catch(err => {
        this.showFormMissing = true;
      });


      if(e) {
        this.showFormMissing = false;
        this.formId = e.data.form_id;
        this.redirectURL = e.data.redirect_url;
        this.redirectInIframe = e.data.redirect_in_iframe;
        this.formName = e.data.form_name;
        this.formHash = hash;
        this.submitValue = e.data.submit_value;
        this.submitAction = e.data.submit_action;
        this.formType = e.data.form_type || 0;
        if(this.formType === 0) {
          this.initMaglrForm(e.data);
        } else {
          this.initExternalForm(e.data);
        }
      } else {
        this.showFormMissing = true;
      }
    }
  },

  computed: {
    getSubmitSuccessMessage () {
      const x = this.form_data.find(el => {
        return el.type === 'Submit';
      });
      if (x) {
        return x.successMessage[this.language == '1' ? '1' : '2'] || this.successTranslations[this.language == '1' ? '1' : '2'];
      } else {
         return this.successTranslations[this.language == '1' ? '1' : '2'];
      }
    },

    getBuildModeError () {
      return this.buildErrorMessage[this.language == '1' ? '1' : '2'];
    },

    isFormGated () {
      return this.getQueryParams('formmode', window.location) === 'gated' ? true : false;
    },

    isBuildMode () {
      return this.getQueryParams('builder', window.location) === '1' ? true : false;
    },

    getStyle () {
      if(this.loaded) {
        return {
          'color': this.settings.textColor,
          'background-color': this.settings.formBackgroundColor
        }
      }
      return {};
    },

    getErrorStyle() {
      return {
        "background-color": this.settings.errorBackgroundColor,
        "color": this.settings.errorTextColor,
        "font-family": this.settings.generalFontFamily.family_name,
        'font-weight': this.settings.generalFontFamily.font_weight,
        'font-style': this.settings.generalFontFamily.font_style
      };
    },

    getTextStyle () {
      return {
        'font-family': this.settings.generalFontFamily.family_name,
        'font-weight': this.settings.generalFontFamily.font_weight,
        'font-style': this.settings.generalFontFamily.font_style,
        'color': this.settings.generalTextColor
      }
    },

    getLoaderStyle () {
      return {
        'fill': this.settings.generalTextColor
      }
    }
  },

  beforeDestroy () {
    window.removeEventListener('message', this.handleMessage, true);
    window.removeEventListener('resize', this.handleMessage, true);
  },

  methods:{
    getPropFromUrl(data, prop){
      try{
          return (new URLSearchParams(data)).get(prop);
      } catch(e) {
          console.error(e);
          return false;
      }
    },

    getFieldError (element) {
      return element.errorMessage[this.language] || this.fieldError[this.language == '1' ? '1' : '2'];
    },

    // Initialise the maglr form
    initMaglrForm(data){
        this.uuid = this.getUUID();
        data.form_data.forEach((el, i) => {
          this.$set(data.form_data[i], 'error', false);
        });
        this.form_data = data.form_data;
        if(data.form_settings){
          this.settings = data.form_settings;
        }else{
          this.settings = data.settings;
        }
        const clientId = data.client_id || data.cl_client_client_id;
        this.language = this.getQueryParams("language", window.location);
        const head = document.head || document.getElementsByTagName('head')[0];

        // Add the fonts file
        if(!document.getElementById('font-css')) {
          let style = document.createElement('link');
          const uri = process.env.VUE_APP_MAGLR_DATA_URL;
          style.setAttribute('href', uri + "/" + clientId + '/fonts/fonts.css');
          style.setAttribute('rel', 'stylesheet');
          style.setAttribute('id', 'font-css');
          style.setAttribute('type', 'text/css');
          style.addEventListener('load', ()  => {
            let filteredFonts = [];
            Object.keys(this.settings).filter(key => {
              if (key.includes('FontFamily')) {
                filteredFonts.push(this.settings[key]);
              }
            });
            this.fontPing(filteredFonts);
          });
          head.appendChild(style);
        }
      this.loaded = true;
    },

    // If a font failed loading, retry 10 times
    fontPing (fonts) {
      if (this.pingIndex < 10) {
        this.pingIndex++;
        let failed = false;
        if(document.fonts) {
          fonts.forEach(el => {
            if(!document.fonts.check('16px ' + el.family_name)) {
              failed = true;
            }
          });
          if(failed) {
            window.setTimeout(()=>{
              this.fontPing(fonts)
            }, 500);
          } else {
            this.resizeEvent();
          }
        } else { //IE dirty timeout
          window.setTimeout(()=>{
            this.resizeEvent();
          }, 500);
        }

      }
    },

    //Resize the form if iframe size changes
    resizeEvent () {
      if(this.loaded) {
        let formHeight;
        if(this.formType === 0) {
           formHeight = document.getElementById('renderer-wrapper').offsetHeight;
        } else if(this.formType === 2) {// Marketo
          formHeight = document.getElementsByClassName('mktoForm')[0].offsetHeight;
        }
        window.parent.postMessage({message: 'FORM_HEIGHT', height: formHeight}, '*');
      }
    },

    // Initliase external form
    initExternalForm (data) {
      this.form_data = null;
      this.formType = data.form_type;
      let forms = document.querySelectorAll('[id^="hbspt-form"]');
      if (forms) {
        forms.forEach((form, i) => {
          forms[i].parentNode.removeChild(forms[i]);
        });
      }
      switch(this.formType) {
        case 1: // Hubspot
          this.initHubspot(data);
          break;
        case 2: // Marketo
          this.initMkto(data);
          break;
        case 3: // Pardot
          this.initPardot(data);
          break;
        case 4: // Mailchimp
          this.initMailchimp(data);
          break;
      }
    },

    // Pardot form initialisation
    initPardot (data) {
      let frame = document.createElement('iframe');
      if (document.getElementById('pardot-iframe')) {
        frame.setAttribute('src', '');
        frame.setAttribute('src', data.embed_string);
      } else {
        frame.setAttribute('id', 'pardot-iframe');
        frame.setAttribute('src', data.embed_string);
        document.getElementById('form-renderer').appendChild(frame);
      }
    },

    //Initialisation of Hubspot form
    initHubspot (data) {
      var hsObj = eval('(' + data.embed_string.split('(')[1].split(')')[0] + ')');
      const node = document.getElementsByClassName("hbspt-form")[0];
      if (node) {
        node.innerHTML = '';
      }
      let scriptMissing = true;
      const scripts = document.getElementsByTagName('script');
      [...scripts].forEach(scrpt => {
        if (scrpt.outerHTML.indexOf('hsforms') > -1){
          scriptMissing = false;
        }
      });
      // Does the script already exists (prevents duplicate forms)
      if(scriptMissing) {
        this.hsScript = document.createElement('script');
        this.hsScript.setAttribute('src', 'https://js.hsforms.net/forms/shell.js');
        document.head.appendChild(this.hsScript);
      } else {
        hbspt.forms.create({ portalId: hsObj.portalId, formId: hsObj.formId, target: '#form-renderer',
          onFormSubmitted: (event) => {
            this.postFormSubmitSuccess()
          },
        });
      }
      try {
        //Load the Hubspot form
        this.hsScript.removeEventListener('load', (e) => {});
        this.hsScript.addEventListener('load', (e) => {
          hbspt.forms.create({ portalId: hsObj.portalId, formId: hsObj.formId, target: '#form-renderer',
            onFormSubmitted: (event) => {
              this.postFormSubmitSuccess()
            },
          });
        });
      } catch(e) {
        alert(e); // error in the above string (in this case, yes)!
      }
    },

    // Initialise the mailchimp form
    initMailchimp(data) {
      let frame = document.createElement('iframe');
      if (document.getElementById('mailchimp-iframe')) {
        frame.setAttribute('src', '');
        frame.setAttribute('src', data.embed_string);
      } else {
        frame.setAttribute('id', 'mailchimp-iframe');
        frame.setAttribute('src', data.embed_string);
        document.getElementById('form-renderer').appendChild(frame);
      }
    },

    getMKProps(data, id){
      try{
        return data.split('MktoForms2.loadForm')[1].split(';')[0].split(',')[id].replace(/[\|&;\$%@"<>\(\)\+,\s]/g, "")
      }catch(e){
        console.log('MK Failed to fetch props',e);
      }
    },

    //Initialise Marketo form
    initMkto (data) {
      const ms = document.createElement('script');
      const src = data.embed_string.split('src="')[1].split('"')[0];
      ms.setAttribute('src', src);
      const node = document.getElementsByClassName("mktoForm")[0];
      if (node) {
        node.parentNode.innerHTML = '';
      } else {
        document.head.appendChild(ms);
      }
      const form = document.createElement('form');

      const formid = data.embed_string.split('id="')[1].split('">')[0];
      const hash = this.getMKProps(data.embed_string, 1);//fetches second param XXX-XXX-XXX hash
      const id = parseInt(this.getMKProps(data.embed_string, 2));//fetch id Marketo id

      form.setAttribute('id', formid);
      document.getElementById('form-renderer').appendChild(form);
      try {
        // Does an instance of Marketo already exist?
        if (!node) {
          ms.removeEventListener('load', (e)=>{});
          // Load Marketo form
          ms.addEventListener('load', () => {
            MktoForms2.loadForm(src.split('/js')[0], hash, id, (form) => {
              form.onSuccess((values, followUp) => {
                this.postFormSubmitSuccess()
              });
            });
            MktoForms2.onFormRender((form)=> {
              this.loaded = true;
              this.resizeEvent();
            });
          });
        } else {
          MktoForms2.loadForm(src.split('/js')[0], hash, id);
        }
      } catch(e) {
        alert(e); // error  in the above string (in this case, yes)!
      }
    },

    // Messages from parent frames
    handleMessage(e) {
      // Message from the editor
      if(e.data.message === 'BUILD_FORM') {
        if(e.data.form.form_type >= 1) {
          this.initExternalForm(e.data.form);
        } else {
          this.initMaglrForm(e.data);
        }
      }

      if(e.data.message === 'TOP_URL') {
        // Is needed to submit the form.
        this.hostname = e.data.data.hostname;
        this.fullUrl = e.data.data.href || e.data.data.fullURL;
        if(this.formSubmitted) {
          // Form submit is ready, if the hostname wasn't there, submit the form with hostname
          this.submitForm();
        }
      }

      if (e.data.message === "FORM_SUBMIT_SUCCESS") {
        this.postFormSubmitSuccess()
      }
    },

    // get form uuid
    getUUID() {
      let uuid = this.getQueryParams('uuid', window.location);
      return uuid;
    },

    // returns specific parameter
    getQueryParams( params, url ) {
      let href = url;
      let reg = new RegExp( '[?&]' + params + '=([^&#]*)', 'i' );
      let queryString = reg.exec(href);
      return queryString ? queryString[1] : null;
    },

    // Validation of a single element
    elementErrorCheck (element) {
      if(element.mandatory) {
        if (element.type === 'MultiCheckbox') { //only 1 option has to be set for checkbox to be valid
          if (element.values.length > 0) {
            this.$set(element, 'error', false);
          } else {
            this.$set(element, 'error', true);
          }
        } else if(element.type !== "TextElement" && element.type !== "Submit" && element.type !== "Divider" && element.type !== 'Title'){
          if (element.value && element.value !== "") {
            this.$set(element, 'error', false);
          } else {
            // this.$set(element, 'error', true);
          }
        }
      }
      this.resizeEvent();
    },

    // Loop through elements and see if they have been filled in
    errorCheck() {
      let empty = false;
      this.form_data.forEach( (el, i) => {
        if (el.type === "TextElement" && el.type === "Submit" && el.type === "Divider" && el.type === 'Title') {
            return;
        }
        if (!el.mandatory) { return; }
        if (el.type === 'MultiCheckbox') { //only 1 option has to be set for checkbox to be valid
            if (el.values.length > 0) {
                this.$set(this.form_data[i], 'error', false);
            } else {
                this.$set(this.form_data[i], 'error', true);
                empty = true;
            }
            } else if (el.type === "Select" || el.type === 'TextInput' || el.type === 'TextArea' || el.type === "Checkbox" ){
            if (el.value && el.value !== "") {
                this.$set(this.form_data[i], 'error', false);
            } else {
                console.log(el.value, el.type, this.form_data[i]);
                this.$set(this.form_data[i], 'error', true);
                empty = true;
            }
        }
      });
      return empty;
    },

    //Update form element
    updateElement (id, val, optionId) {
      this.form_data.forEach((el, i) => {
        if (el.id === id) {
          //checkboxes don't get set on form_data element, but on their options instead
          if (optionId) {
            el.options.forEach((o, j) => {
              if (optionId === o.id) {
                this.$set(this.form_data[i].options[j], "value", val);
              }
            });
          } else {
            // Multicheckboxes have multiple values that will be set in an array to eventually post the data correctly.
            if (el.type === 'MultiCheckbox') {
              this.$set(this.form_data[i], "values", val);
            } else {
              this.$set(this.form_data[i], "value", val);
            }
          }
        }
      });
    },

    updateCaptcha (captchaValue) {
      this.formCaptcha = captchaValue;
    },

        // Check if captcha is needed
    hasCaptcha () {
      return !!this.form_data.find(el => el.type === "Captcha");
    },

    //Submit the form
    async submitForm () {
      this.submitting = true;
      this.showIsInBuildModeError = false;
      this.hasBeenSubmitted = true;
      // Validate the form
      if (this.errorCheck()) {
        this.submitting = false;
        this.$nextTick(() => {
          this.resizeEvent();
        })
        return;
      } else {
        this.formSubmitted = true;
        const url = process.env.VUE_APP_MAGLR_DASHBOARD_URL;

        // Filter the data to post the form
        let formData = this.form_data.filter(formElement => {
          return formElement.type === 'Checkbox' ||  formElement.type === 'MultiCheckbox'
          || formElement.type === 'TextInput' || formElement.type === 'RadioButton'
          || formElement.type === 'Select' || formElement.type === 'TextArea' || formElement.type === 'Captcha';
        });

        formData.forEach((formElement, i) => {
          if (formElement.type === 'MultiCheckbox') {
            const mappedValues = formElement.values.map(value => {return {value: value, name: formElement.name}});
            for(let j = 0; j < mappedValues.length; j++) {
              formData.splice(i+1, 0, mappedValues[j]);
            }
            formData.splice(i, 1);
          }
        });


        let postdata = {
          form_id: this.formId,
          form_hash: this.formHash,
          form_captcha: this.formCaptcha,
          submit_action: this.submitAction,
          submit_value: this.submitValue,
          domain: this.hostname,
          form_data: formData.map(formElement => {
            return {value: formElement.value, name: formElement.name};
          })
        }
        this.fullUrl && postdata.form_data.push({name: 'FormSubmitUrl', value: this.fullUrl})


        // When a form is posted in the editor, do not post it.
        if (this.isBuildMode) {
          this.showIsInBuildModeError = true;
          return;
        }

        // Check if captcha is required and filled in
        if (this.hasCaptcha() && !this.formCaptcha) {
          this.showErrorMessage = true;
          return
        }

        if (!this.hostname) {
          //embed with template-spread iframe
          if(parent.parent !== top){
            parent.parent.postMessage({message: 'GET_TOP_URL'}, '*');
          }
          //embed with advanced page
          if(parent !== top){
            parent.postMessage({message: 'GET_TOP_URL'}, '*');
          }
          //original frontend
          if(parent === top){
            window.top.postMessage({message: 'GET_TOP_URL'}, '*');
          }
          return;
        }

        this.waitingForApi = true
        const res = await axios.post(url + '/en_EN/api/ContactFormMustache.json', postdata).catch(err => {
          // Something went wrong
          this.waitingForApi = false
          this.showErrorMessage = true;
        });

        if (res?.data.status === 'ok') {
          // The form has been successfully posted
          this.postFormSubmitSuccess()
          if (!this.redirectURL) {
            this.waitingForApi = false
          }
          if (this.redirectURL && this.redirectInIframe) {
            window.location.href = this.redirectURL;
          }
          this.showSuccessMessage = true;
        } else {
          this.showErrorMessage = true;
        }
        this.submitting = false;
      }
    },

    postFormSubmitSuccess() {
      window.parent.postMessage({message: 'FORM_SUBMIT_SUCCESS', formName: this.formName, redirectURL: this.redirectInIframe ? null : this.redirectURL, uuid: this.uuid}, '*');
    }
  }
}
</script>
<style lang="scss" type='text/css'>
body {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
form {
  width: 100%;
}

.hubspot-bg{
  background-color: white;
}
#hubspot-container {
  position: relative;
}
#form-renderer {
  width: 100%;
  display: flex;
  height: 100%;
  position: relative;
  min-height: 100vh;
  margin: auto;
}
 .hs-form-iframe {
    padding: 45px !important;
  }
.mktoForm  {
  padding: 45px !important;
  display: block;
  margin: auto;
}
#renderer-wrapper {
  display: flex;
  padding: 10px;
  flex-direction: column;
  width:100%;
}
.form-gated {
  padding: 45px !important;
  max-width: 600px !important;
  margin: auto !important;
}

.label {
  font-weight: bold;
  margin-bottom: 10px;
}
p {
  margin: 0px;
}
.container {
  position: relative;
  margin-top: 25px;
  display: flex;
  &.error {
    margin-bottom: 50px;
  }
}
.container-row {
  flex-direction: row;
  align-items: center;
  .input {
    margin-bottom: 0px;
    width: 100%;
  }
  .label {
    margin-bottom: 0px;
    margin-right: 20px;
    min-width: 33%;
    max-width: 30%;
    display: flex;
  }
}
.input-wrapper {
    width: 100%;
    display: flex;
    position: relative;
}
.container-col {
  flex-direction: column;
}
.error-label {
  position: absolute;
  padding: 5px 13px;
  max-width: 600px;
  color: white;
  font-size: 13px;
  background-color: #D0021B;
  bottom: -28px !important;
  display: flex;
  white-space: nowrap;
}
.container-row {
  .error-label {
    bottom: -25px;
    max-width: 100%;
  }
}
@media only screen and (max-width: 450px) {
  .hs-form-iframe {
    padding: 25px !important;
  }
  .mktoForm  {
    padding: 25px !important;
  }
  #renderer-wrapper {
    padding: 10px !important;
  }
  .form-gated {
    padding: 25px !important;
  }
  .container-row {
    flex-direction: column;
    align-items: start;
    .label {
      margin-bottom: 10px;
      min-width: 100% !important;
    }

  }
}
legend {
  padding: 0;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.form-missing-wrapper {
  .form-missing-text {
    font-family: sans-serif;
    font-size: 1em;
  }
}

.builder-error-message {
    height: 40px;
    display: inline-flex;
    align-items: center;
    background-color: #D0021B;
    margin-bottom: 20px;
    color: white;
    margin-top: 20px;
    padding: 0px 20px;
}

#pardot-iframe, #mailchimp-iframe {
  border: 0px;
  height: 100vh;
  width: 100vw;
  display: block;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;

  & svg{
    width: 150;
    height: 150px;
    margin: 20px;
    display:inline-block;
  }
}

/* scrollbar */
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
} /* this targets the default scrollbar (compulsory) */
::-webkit-scrollbar-track {
      background-color: #dbe1e4;
} /* the new scrollbar will have a flat appearance with the set background color */
::-webkit-scrollbar-thumb {
      background-color: #8e9fa9;
}
</style>

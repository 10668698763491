<template>
     <div :class='getLabelPosition' class='container'>
        <label class='label' :for='"element_" + element.id' :style='getLabelStyle'>{{element.label[$parent.language]}}</label>
         <div class='input-wrapper'>
            <input 
                class='input'
                @blur='checkValue' 
                :class='{"error": error || element.error}' 
                :name='element.label[$parent.language]'
                :label='"element_" + element.id' 
                :id='"element_" + element.id' 
                :type="element.autocomplete.type"
                :aria-required="element.mandatory" 
                :required="element.mandatory" 
                :aria-invalid="error || element.error"
                :autocomplete="element.autocomplete.tag"
                v-model="val" 
                :placeholder='element.placeholder[$parent.language]'
                @input="update(element.id)" 
                :style='getStyle'
            />
            <span class='error-label' :style='$parent.getErrorStyle' v-if='error || element.error' :id='"error_" + element.id'>{{$parent.getFieldError(element)}}</span>
         </div>
     </div>

</template>
<script>
export default {
    name: 'TextInput',
    data () {
        return {
            val: '',
            error: false
        }
    },

     props: ['element', 'settings'],

     computed: {
        getLabelPosition () {
            return {'container-row': this.settings.labelPosition === 'left', 'container-col': this.settings.labelPosition === 'top', "error": this.error || this.element.error  };
        },
        getLabelStyle () {
            return {
                'font-family': this.settings.labelFontFamily.family_name,
                'font-weight': this.settings.labelFontFamily.font_weight,
                'font-style': this.settings.labelFontFamily.font_style,
                'color': this.settings.labelColor
            }
        },
        getStyle () {
            if(this.settings.borderType === 'border-bottom') {
                return {
                    'font-family': this.settings.inputFontFamily.family_name,
                    'font-weight': this.settings.inputFontFamily.font_weight,
                    'font-style': this.settings.inputFontFamily.style,
                    'background-color': this.settings.inputBackgroundColor,
                    'border': 'none',
                    'border-bottom-width': this.settings.borderWidth,
                    'border-bottom-style': 'solid',
                    'border-bottom-color': this.element.error ? this.settings.errorBackgroundColor : this.settings.borderColor,
                    'color': this.settings.inputColor
                }
            } 
            return {
                'font-family': this.settings.inputFontFamily.family_name,
                'font-weight': this.settings.inputFontFamily.font_weight,
                'font-style': this.settings.inputFontFamily.style,
                'background-color': this.settings.inputBackgroundColor,
                'border-radius': this.settings.borderType === 'square' ? '0px' : '3px',
                'border-width': this.settings.borderWidth,
                'border-color': this.element.error ? this.settings.errorBackgroundColor : this.settings.borderColor,
                'color': this.settings.inputColor
            }
        },
     },

     methods: {
        checkValue () {
            if (this.element.mandatory) {
                if(this.val) {
                    this.error = false;
                } else {
                    this.error = true;
                }
            }
            this.error = false;
        },
        update (id) {
            this.checkValue();
            this.$emit('updateElement', id, this.val);
        }
     },

     watch:{
        val (newVal) {
            if (this.$parent.hasBeenSubmitted) {
                this.$emit('errorCheck');
            }
        },
     } 
}
</script>
<style type='text/css' scoped lang='scss'>
.error-label {
    bottom: -25px;
    max-width: 100%;
}
.label { 
    font-size: 16px;
}
 .input {
    -webkit-appearance: none;
    height: 40px;
    width: 100%;
    font-size: 16px;
    outline: none;
    padding: 0px 10px;
    border-style: solid;
    &.border-bottom {
        border: none;
        outline: none;
    }

    &:focus {
        border-color: #000 !important;  
    }
}
</style>
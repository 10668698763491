import { render, staticRenderFns } from "./radio.vue?vue&type=template&id=5c94ce28&scoped=true&"
import script from "./radio.vue?vue&type=script&lang=js&"
export * from "./radio.vue?vue&type=script&lang=js&"
import style0 from "./radio.vue?vue&type=style&index=0&id=5c94ce28&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c94ce28",
  null
  
)

export default component.exports
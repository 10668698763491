<template>
     <div class='confirmation' :style='$parent.getStyle'>
         {{$parent.getSubmitSuccessMessage}}
     </div>
</template>
<script>
export default {
    name: 'Confirmation',
    data () {
        return {
            
        }
    },
    created () {
        window.parent.postMessage({message: 'FORM_SUBMIT_SUCCESS'}, '*');
    }
}
</script>
<style type='text/css' scoped lang='scss'>
</style>
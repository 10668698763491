<template>
    <fieldset class='text-wrapper' >
        <p class='text' v-html='element.label[$parent.language]' :style='getStyle'></p>
    </fieldset>
</template>
<script>
export default {
     name: 'TextElement',
        data () {
            return {
            }
        },

    props: ['element', 'settings'],
    created() {
    },
     computed: {
        getStyle () {
            return {
                'color': this.settings.generalTextColor,
                'font-family': this.settings.generalFontFamily.family_name,
                'font-weight': this.settings.generalFontFamily.font_weight,
                'font-style': this.settings.generalFontFamily.font_style,
            }
        },
     },
}
</script>
<style type='text/css' scoped lang='scss'>
.text-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
    .text {
        line-height: 1.5em;
    }
}
legend {
    font-size: 24px;
    font-weight: bold !important;
}


</style>
<template>
     <div :class='getLabelPosition' class='container' :aria-labelledby="element.label[$parent.language]">
        <label class='label' :id='element.label[$parent.language]' :style='getLabelStyle'>{{element.label[$parent.language]}}</label>
        <div class='inputs-wrapper'>
          <div class="input-wrapper" v-for='(option) in element.options' :key='element.id+"_"+option.id' :id='element.id+"_"+option.id'>
              <input
                class='input'
                :label='option.label[$parent.language]'
                :name='element.label[$parent.language]'
                :id='"element_" + element.id + "_" + option.id'
                type='radio'
                :value='option.value'
                v-model='value'
              />
              <label :for='"element_" + element.id + "_" + option.id' :style='getTextStyle'>{{option.label[$parent.language]}}</label>
              <span @click='value = option.value' class="checkmark" :class='{"checked": option.value === value}' :style='getStyle'></span>
          </div>
          <span class='error-label' :style='$parent.getErrorStyle' v-if='element.error'>{{$parent.getFieldError(element)}}</span>
        </div>
     </div>
</template>
<script>
export default {
     name: 'Radio',
         data () {
            return {
                value: false,
            }
     },
     created () {
       this.value = this.element.options[0].value;
     },
     methods: {

     },
     computed: {
        getLabelStyle () {
            return {
                'font-family': this.settings.labelFontFamily.family_name,
                'font-weight': this.settings.labelFontFamily.font_weight,
                'font-style': this.settings.labelFontFamily.font_style,
                'color': this.settings.labelColor
            }
        },

        getTextStyle () {
            return {
                'color': this.settings.generalTextColor,
                'font-family': this.settings.generalFontFamily.family_name,
                'font-weight': this.settings.generalFontFamily.font_weight,
                'font-style': this.settings.generalFontFamily.font_style,
            }
        },

        getStyle () {
          if(this.element.error) {
            return {
              'background-color': this.settings.inputBackgroundColor,
              'border-width': this.settings.borderWidth,
              'border-color': this.element.error ? this.settings.errorBackgroundColor : this.settings.borderColor,
              'color': this.settings.textcolor
            }
          }
          return {};
        },
        getLabelPosition () {
            return {'container-row': this.settings.labelPosition === 'left', 'container-col': this.settings.labelPosition === 'top', "error": this.element.error  };
        },
     },
     watch:{
      value (newVal) {
        this.$emit('updateElement', this.element.id, newVal);
      }
     },
     props: ['element', 'settings'],

}
</script>
<style type='text/css' scoped lang='scss'>
.container-row {
  align-items: start;
}
.label {
  margin-bottom: 10px;
  font-size: 16px;
}
.inputs-wrapper {
  display: flex;
  flex-direction: column;
}
/* Customize the label (the container) */
.input-wrapper {
  display: block;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  label {
    font-size: 16px;
    padding-left: 35px;
    display: block;
    cursor: pointer;
  }
}



/* Hide the browser's default radio button */
.input-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  background-color: #fff;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: #B8B8B8;
  border: 1px solid #B8B8B8;
    &.checked{
      &:after {
        display: block;
      }
    }
    &:after {
      width: 6px;
      height: 6px;
      top: 4px;
      left: 4px;
      padding: 3px;
      border-radius: 50%;
      background-color: #2196F3 !important;
    }
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.input-wrapper input:checked ~ .checkmark:after {
  display: block;
}
.input-wrapper input:focus ~ .checkmark {
  border-color: #000 !important;
}
</style>

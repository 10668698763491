<template>
     <div :class='getLabelPosition' class='container'>
        <label class='label' :for='"element_" + element.id' :style='getLabelStyle'>{{element.label[$parent.language]}}</label>
        <div class='input-wrapper'>
            <select class='input'
                :label='element.id'
                :class='{"error": element.error}'
                :for='"element_" + element.id'
                :id='"element_" + element.id'
                :aria-required="element.mandatory"
                :aria-invalid="element.error"
                type='select'
                v-model="value"
                :style='getStyle'
            >
                <option
                    v-for='(o, i) in element.options'
                    :key='element.id+"_"+i'
                    :label='o.label[$parent.language]'
                    :value='o.value'
                    :style='getStyle'
                    :disabled="i === 0"
                >
                    {{o.label[$parent.language]}}
                </option>
            </select>
            <label class='error-label' :style='$parent.getErrorStyle' v-show='element.error'>{{$parent.getFieldError(element)}}</label>
        </div>

     </div>
</template>
<script>
export default {
     name: 'Select', // eslint-disable-line vue/no-reserved-component-names
         data () {
             return {
                value: 0,
         }
     },

     created () {
        this.element.error = false; // eslint-disable-line vue/no-mutating-props
        this.value =  this.element.options[0].value;
     },

     props: ['element', 'settings'],

     computed: {
         getLabelPosition () {
            return {'container-row': this.settings.labelPosition === 'left', 'container-col': this.settings.labelPosition === 'top', "error": this.element.error  };
        },
        getLabelStyle () {
            return {
                'font-family': this.settings.labelFontFamily.family_name,
                'font-weight': this.settings.labelFontFamily.font_weight,
                'font-style': this.settings.labelFontFamily.font_style,
                'color': this.settings.labelColor
            }
        },
        getStyle () {
            if(this.settings.borderType === 'border-bottom') {
                return {
                    'font-family': this.settings.inputFontFamily.family_name,
                    'font-weight': this.settings.inputFontFamily.font_weight,
                    'font-style': this.settings.inputFontFamily.style,
                    'background-color': this.settings.inputBackgroundColor,
                    'border': 'none',
                    'border-bottom-width': this.settings.borderWidth,
                    'border-bottom-style': 'solid',
                    'border-bottom-color': this.element.error ? this.settings.errorBackgroundColor : this.settings.borderColor,
                    'color': this.settings.inputColor
                }
            }
            return {
                'font-family': this.settings.inputFontFamily.family_name,
                'font-weight': this.settings.inputFontFamily.font_weight,
                'font-style': this.settings.inputFontFamily.style,
                'background-color': this.settings.inputBackgroundColor,
                'border-radius': this.settings.borderType === 'square' ? '0px' : '3px',
                'border-width': this.settings.borderWidth,
                'border-color': this.element.error ? this.settings.errorBackgroundColor : this.settings.borderColor,
                'color': this.settings.inputColor
            }
        }
     },

    watch: {
        value(newVal) {
            this.$emit('updateElement', this.element.id, newVal);
            if (this.$parent.hasBeenSubmitted) {
                this.$emit('errorCheck');
            }
        },
      element: {
        handler (newVal){
          if (this.$parent.hasBeenSubmitted) {
            this.$emit('errorCheck');
          }
        },
        deep: true
      },
    },

    methods: {

    }
}
</script>
<style type='text/css' scoped lang='scss'>

.delete {
    cursor:pointer;
    margin-left: 40px;
}

.label {
margin-bottom: 10px;
  font-size: 16px;
}
.input-wrapper {
    width: 100%;
    position: relative;
    display: flex;
}
.input {
    width: 100%;
}
select, select:focus {
    height: 40px;
    font-size: 16px;
    padding: 0px 10px;
    outline: none;
    outline: #bebdbe;
    border: 1px solid #bebdbe;
    &:focus{
        border-color: #000 !important;
    }
}

.error-label {
    bottom: -25px !important;
}
</style>

<template>
     <div :class='getLabelPosition' class='container'>
        <label class='label' :for='"element_" + element.id' :style='getLabelStyle'>{{element.label[$parent.language]}}</label>
        <div class='input-wrapper'>
            <textarea 
                class='input' 
                :class='{"error": error || element.error}' 
                :name='element.label[$parent.language]'
                :label='"element_" + element.id' 
                :id='"element_" + element.id' 
                type='textarea' 
                :placeholder='element.placeholder[$parent.language]'
                :required="element.mandatory" 
                v-model="val" 
                @input="update(element.id)" 
                :style='getStyle'
            />
            <span class='error-label' :style='$parent.getErrorStyle' v-if='error || element.error' :id='"error_" + element.id'>{{$parent.getFieldError(element)}}</span>
        </div>
     </div>
</template>
<script>
export default {
     name: 'TextArea',
         data () {
             return {
                val: '',
                error: false,
         }
     },
     props: ['element', 'settings'],
     computed: {
        getLabelPosition () {
            return {'container-row': this.settings.labelPosition === 'left', 'container-col': this.settings.labelPosition === 'top', "error": this.error || this.element.error  };
        },
        getLabelStyle () {
            return {
                'font-family': this.settings.labelFontFamily.family_name,
                'font-weight': this.settings.labelFontFamily.font_weight,
                'font-style': this.settings.labelFontFamily.font_style,
                'color': this.settings.labelColor
            }
        },
       getStyle () {
            if(this.settings.borderType === 'border-bottom') {
                return {
                    'font-family': this.settings.inputFontFamily.family_name,
                    'font-weight': this.settings.inputFontFamily.font_weight,
                    'font-style': this.settings.inputFontFamily.style,
                    'background-color': this.settings.inputBackgroundColor,
                    'border': 'none',
                    'border-radius': this.settings.borderRadius,
                    'border-bottom-width': this.settings.borderWidth,
                    'border-bottom-color': this.element.error ? this.settings.errorBackgroundColor : this.settings.borderColor,
                    'color': this.settings.inputColor,
                    'height': this.settings.textAreaHeight
                }
            } 
            return {
                'font-family': this.settings.inputFontFamily.family_name,
                'font-weight': this.settings.inputFontFamily.font_weight,
                'font-style': this.settings.inputFontFamily.style,
                'background-color': this.settings.inputBackgroundColor,
                'border-radius': this.settings.borderType === 'square' ? '0px' : '3px',
                'border-width': this.settings.borderWidth,
                'border-color': this.element.error ? this.settings.errorBackgroundColor : this.settings.borderColor,
                'color': this.settings.inputColor,
                'height': this.settings.textAreaHeight
            }
        }
     },
    methods: {
        update (id) {
            this.checkValue();
            this.$emit('updateElement', id, this.val);
        },
        checkValue () {
            if (this.element.mandatory) {
                if(this.val) {
                    this.error = false;
                } else {
                    this.error = true;
                }
            }
            this.error = false;
        },
    
    },
    watch:{
        val (newVal) {
            if (this.$parent.hasBeenSubmitted) {
                this.$emit('errorCheck');
            }
        },
     } 
}
</script>
<style type='text/css' scoped lang='scss'>
textarea { 
    width: 100%;
  font-size: 16px;
    border-style: solid;
    &:focus {
    outline: #000 solid 1px;
}
}
.input {
    height: 250px;
    outline: none;
    padding: 13px 10px;
    resize: none;
    width: 100%;
    font-size: 16px;
    
}

.label {
    font-size: 16px;
    width: 100%;
}
.container-row {
    align-items: start;
}

</style>